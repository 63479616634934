import {logger} from 'tizra'
import * as B from '../block'
import {CheckoutWizard} from './CheckoutWizard'
import {CartBlockConfig} from './meta'
import {OrderSummary} from './OrderSummary'

const log = logger('CartBlock/Checkout')

interface CheckoutProps {
  config: CartBlockConfig
}

export const Checkout = ({config}: CheckoutProps) => {
  const userData = B.useUserData()

  const {
    checkouts,
    defaultCheckout,
    checkoutName,
    setCheckoutName,
    checkout,
    firstIncomplete,
    submit,
    loading,
  } = B.useCheckout()

  return (
    <>
      <B.Section>
        <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
          {userData === null || !checkouts ?
            <div />
          : <CheckoutWizard
              // Re-render from scratch whenever checkoutName or firstIncomplete
              // changes.
              key={`${checkoutName || '_pick'}:${firstIncomplete ?? -1}`}
              {...{
                config,
                userData,
                checkouts,
                defaultCheckout,
                checkoutName,
                setCheckoutName,
                checkout,
                firstIncomplete,
                submit,
              }}
            />
          }
          <OrderSummary checkout={checkout} config={config} />
        </B.Grid>
      </B.Section>
      <B.Spinner loading={loading} fullscreen />
    </>
  )
}
