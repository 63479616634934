import classNames from 'classnames'
import S from './styles.module.css'
import {IS_CHROMATIC} from 'tizra'
import {ComponentProps, useEffect, useState} from 'react'

// from https://ariakit.org/examples/popover-lazy
const SpinnerSvg = (props: ComponentProps<'svg'>) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
    className={classNames(S.spinner, props.className)}
  >
    <path
      d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
      opacity="0.3"
    />
    <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
  </svg>
)

interface SpinnerProps {
  fullscreen?: boolean
  inline?: boolean
  loading?: boolean
  animate?: boolean
}

export const Spinner = ({
  inline = false,
  fullscreen = false,
  loading = true,
  animate = !IS_CHROMATIC,
}: SpinnerProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (loading) setVisible(true)
  }, [loading])

  if (!loading && !visible) return null

  const spinner = <SpinnerSvg {...(!animate && {style: {animation: 'none'}})} />

  return inline ? spinner : (
      <div
        className={classNames(S.overlay, {
          [S.fullscreen]: fullscreen,
          [S.fade]: !loading || !visible,
        })}
        onTransitionEnd={() => {
          if (!loading) setVisible(false)
        }}
        // This effectively kills the transition while preserving onTransitionEnd.
        {...(!animate && {style: {transitionDuration: '0ms'}})}
      >
        {spinner}
      </div>
    )
}
