import {useEffect, useState} from 'react'
import {
  ApiError,
  Checkout,
  Checkouts,
  FormErrors,
  api,
  isEmptyObj,
  logger,
} from 'tizra'
import {useApi} from './useApi'
import {useHack} from './useHack'

const log = logger('useCheckout')

export const FULFILLER_BUTTONS_CHECKOUT_NAME = '_fulfiller-method'
export const FULFILLER_BUTTONS_STEP_NAME = '_fulfiller-step'

export const classicMeta: Checkouts = {
  checkouts: [
    {
      displayName: 'Classic checkout',
      name: FULFILLER_BUTTONS_CHECKOUT_NAME,
    },
  ],
  defaultCheckout: FULFILLER_BUTTONS_CHECKOUT_NAME,
}

export const classicCheckout: Checkout = {
  ...classicMeta.checkouts[0],
  checkoutStatus: 'complete',
  cart: [],
  infoItems: [],
  steps: [
    {
      name: FULFILLER_BUTTONS_STEP_NAME,
      displayName: 'Payment',
      final: true,
      optional: false,
    },
  ],
}

const findFirstIncomplete = (checkout: Checkout | undefined) => {
  const found = checkout?.steps.findIndex(step => step.status !== 'complete')
  return found === -1 ? undefined : found
}

const resolveCheckoutName = (
  meta: Checkouts | undefined,
  checkoutName: string | undefined,
) => {
  return (
    !meta ? undefined
    : meta.checkouts.length === 1 ? meta.checkouts[0].name
    : checkoutName && meta.checkouts.some(cc => cc.name === checkoutName) ?
      checkoutName
    : meta.defaultCheckout
  )
}

export interface UseCheckoutProps {
  enabled?: boolean
}

export interface UseCheckoutReturn extends Partial<Checkouts> {
  checkoutName?: string
  checkout?: Checkout
  firstIncomplete?: number
  setCheckoutName: (checkoutName: string) => void
  submit: (stepName: string, values: any) => Promise<FormErrors | undefined>
  loading: boolean
}

/**
 * React hook for calling the checkout API.
 */
export const useCheckout = ({
  enabled = true,
}: UseCheckoutProps = {}): UseCheckoutReturn => {
  const checkoutApiEnabled = useHack('checkoutApi')
  const {data: _meta, isLoading: checkoutsLoading} = useApi.checkouts(
    enabled && checkoutApiEnabled,
  )
  const meta =
    checkoutApiEnabled ? _meta
    : enabled ? classicMeta
    : undefined

  const [_checkoutName, setCheckoutName] = useState<string>()
  const checkoutName = resolveCheckoutName(meta, _checkoutName)

  // TODO: additional abstraction to update cart in react-query
  const {data: gotCheckout, isLoading: checkoutLoading} = useApi.getCheckout(
    enabled && checkoutApiEnabled && checkoutName && {checkoutName},
    // Force refetch every time checkoutName changes.
    {gcTime: 0, staleTime: 0},
  )
  const [_checkout, setCheckout] = useState<Checkout | undefined>(gotCheckout)
  const checkout =
    checkoutApiEnabled ?
      !gotCheckout ? undefined
      : _checkout?.name === checkoutName ? _checkout
      : gotCheckout
    : enabled ? classicCheckout
    : undefined

  useEffect(() => setCheckout(gotCheckout), [gotCheckout])

  const [submitting, setSubmitting] = useState<boolean>(false)

  const submit = async (stepName: string, values: any) => {
    if (!enabled)
      return {
        reason: 'enabled=false',
        message: 'Attempted submit with enabled=false',
      }
    if (!checkoutApiEnabled)
      return {
        reason: 'checkoutApiEnabled=false',
        message: 'Attempted submit with checkoutApiEnabled=false',
      }
    if (!checkoutName)
      return {
        reason: 'checkoutName=undefined',
        message: 'Attempted submit with undefined checkoutName',
      }

    setSubmitting(true)

    const {status, ...data} = await api
      .postCheckout({checkoutName, stepName, values})
      .catch(e => {
        // Only here for 500, not 40x per entry in info.ts
        log.error(e)
        return {
          status: (e instanceof ApiError && e.status) || 0,
          reason: 'exception',
          message: `${e}`,
          errors: undefined,
        }
      })

    setSubmitting(false)

    if ('steps' in data) {
      setCheckout(data)
      return
    }

    // If the API returns permission denied, this means the session expired
    // between the time we rendered the form and now, so we need to reload the
    // page.
    if (status === 403) {
      window.location.reload()
      return
    }

    // Normalize some odd responses to always return FormErrors
    let {errors, message, reason} = data || {
      reason: 'empty',
      message: 'Server returned empty response.',
    }
    if (!errors || isEmptyObj(errors)) {
      reason ||= status ? `${status}` : 'unknown'
      message ||= `Unknown error (${reason})`
    } else if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }
    return {errors, message, reason}
  }

  const firstIncomplete = findFirstIncomplete(checkout)

  return log.tap({
    ...meta,
    checkoutName,
    setCheckoutName,
    checkout,
    firstIncomplete,
    submit,
    loading: checkoutsLoading || checkoutLoading || submitting,
  })
}
